import { AuthenticationOptions } from 'core/authentication/models';
import { getCurrentOrgIdFromStateOrPath } from 'app-redux/selectors/orgs.selector';
import { getCurrentWorkspaceIdFromPath } from 'app-redux/selectors/licensingAccounts.selector';
import { createAnalyticsHook } from '@cw-elements/analytics-provider';
import { store } from 'app-redux/store';
import { AppState } from 'app-redux/reducers';
import { history } from 'core/history';

const withUserInfo = () => {
  const state: AppState = store.getState();
  const { currentUser } = state.accounts;

  return {
    userId: currentUser?.id ?? '',
    userName: currentUser?.name ?? '',
    userRole: currentUser?.role ?? '',
    userEmail: currentUser?.email ?? '',
    jobTitle: currentUser?.job_title ?? '',
    providerName: currentUser?.identity_provider ?? '',
    location: currentUser?.country_code ?? '',
    clientId: AuthenticationOptions.clientId ?? '',
  };
}

const withIdentifyInfo = () => {
  const userInfo = withUserInfo();
  return userInfo;
}

const withWorkspaceAndOrgIds = () => {
  const state: AppState = store.getState();
  const currentWorkspaceId = state.workspaces.currentWorkspaceId ?? getCurrentWorkspaceIdFromPath(history.location.pathname);
  const currentOrgId = getCurrentOrgIdFromStateOrPath(state.orgsState);
  return {
    workspaceId: currentWorkspaceId,
    organizationId: currentOrgId
  }
}

export const getGlobalAnalyticsProps = () => ({
  ...withUserInfo(),
  ...withWorkspaceAndOrgIds(),
});

export const getIndentifyProps = () => ({
  ...withIdentifyInfo()
});

export const useGlobalAnalytics = createAnalyticsHook(withUserInfo, withWorkspaceAndOrgIds);
