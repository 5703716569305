import { AxiosResponse } from 'axios';
import { axiosRequest } from 'core/axios';
import { getHeaders } from 'core/common-methods';
import { IWorkspace } from 'core/workspaces/models';
import { createRequestAction, RequestAction } from 'core/utils/actionUtils';
import { UUID } from 'core/utils/BasicModels';
import { sortBy } from 'lodash';
import { Dispatch } from 'redux';

export const WORKSPACES: RequestAction = createRequestAction('GET_LICENSING_ACCOUNTS_REQUEST');
export const ACTION_SET_CURRENT_WORKSPACE = 'SET_CURRENT_LICENSING_ACCOUNT_REQUEST';
export const ACTION_RESET_CURRENT_WORKSPACE = 'RESET_CURRENT_LICENSING_ACCOUNT_REQUEST';


export const fetchWorkspacesByIds =
  (workspaceIds: UUID[]) => async (dispatch: Dispatch) => {
    const workspaces: IWorkspace[] = [];
    const baseURL = process.env.API_URL;
    Promise.all(
      workspaceIds.map(async (workspaceId) => {
        try {
          dispatch({ type: WORKSPACES.REQUEST });
          const endpoint = `/v2/workspaces/${workspaceId}`;
          const workspace = await axiosRequest.get<any, AxiosResponse<IWorkspace>>(
            endpoint,
            {
              headers: getHeaders().common,
              baseURL,
            },
          );

          await axiosRequest.get<any, AxiosResponse<any>>(
            `/v2/store/apps`,
            {
              headers: {
                ...getHeaders().common,
                ...getHeaders().language
              },
              params: {
                licensing_account_id: workspaceId,
              },
            },
          )
          .then((res) => (workspace.data.products = res.data.content))
          .catch((e) => {
            throw e;
          });

          workspaces.push(workspace.data);
        } catch (e) {
          dispatch({ type: WORKSPACES.ERROR, payload: e });
          throw e;
        }
      }),
    )
      .then(() => {
        dispatch({ type: WORKSPACES.SUCCESS, payload: sortBy(workspaces, ['name']) });
      })
      .catch(() =>
        dispatch({ type: WORKSPACES.ERROR, payload: `don't fetch workspaces` }),
      );
  };

export const setCurrentWorkspace = (currentWorkspaceId: UUID) => async (dispatch: Dispatch) => {
  dispatch({ type: ACTION_SET_CURRENT_WORKSPACE, payload: currentWorkspaceId });
};

export const resetCurrentWorkspace = () => async (dispatch: Dispatch) => {
  dispatch({ type: ACTION_RESET_CURRENT_WORKSPACE, payload: '' });
};
