/* tslint:disable:no-duplicated-branches*/
//To Do - remove this and merge branches
import { ACTION_CREATE_ORG, ACTION_CREATE_ORG_IMG, ACTION_DELETE_ORG, ACTION_DELETE_ORG_IMG, ACTION_EDIT_ORG, ACTION_GET_ORG, ACTION_GET_ORG_TEAM, ACTION_GET_ORGS_LIST, ACTION_RESET_CURRENT_ORG, ACTION_SET_CURRENT_ORG } from 'app-redux/actions/org.actions';
import { IUserAccount } from 'core/accounts/models';
import { OrganizationDTO } from 'core/orgs/models';
import { PayloadAction } from 'core/utils/actionUtils';
import { UUID } from 'core/utils/BasicModels';

export interface OrgsState {
  readonly orgs: OrganizationDTO[];
  currentOrgId?: UUID;
  orgTeam?: IUserAccount[];
  isLoadingOrgs: boolean;
  error?: any;
}

export interface OrgMap {[id: string]: OrganizationDTO; }

const initialState: OrgsState = {
  isLoadingOrgs: false,
  orgs: [],
};

export const orgsStateReducer = (state: OrgsState = initialState, action: PayloadAction<any>): OrgsState => {
  switch (action.type) {
    case ACTION_GET_ORGS_LIST.REQUEST:
    case ACTION_CREATE_ORG.REQUEST:
    case ACTION_CREATE_ORG_IMG.REQUEST:
    case ACTION_DELETE_ORG_IMG.REQUEST:
    case ACTION_EDIT_ORG.REQUEST:
    case ACTION_DELETE_ORG.REQUEST:
    case ACTION_GET_ORG.REQUEST:
    case ACTION_RESET_CURRENT_ORG.REQUEST:
    case ACTION_GET_ORG_TEAM.REQUEST:
      return {
        ...state,
        isLoadingOrgs: true,
      };
    case ACTION_GET_ORGS_LIST.SUCCESS:
      return {
        ...state,
        orgs: action.payload,
        isLoadingOrgs: false,
      };
    case ACTION_GET_ORGS_LIST.ERROR:
    case ACTION_CREATE_ORG.ERROR:
    case ACTION_CREATE_ORG_IMG.ERROR:
    case ACTION_DELETE_ORG_IMG.ERROR:
    case ACTION_EDIT_ORG.ERROR:
    case ACTION_DELETE_ORG.ERROR:
    case ACTION_GET_ORG.ERROR:
    case ACTION_RESET_CURRENT_ORG.ERROR:
    case ACTION_GET_ORG_TEAM.ERROR:
      return {
        ...state,
        isLoadingOrgs: false,
        error: action.payload,
      };
    case ACTION_CREATE_ORG.SUCCESS:
    case ACTION_CREATE_ORG_IMG.SUCCESS:
    case ACTION_DELETE_ORG_IMG.SUCCESS:
    case ACTION_EDIT_ORG.SUCCESS:
    case ACTION_DELETE_ORG.SUCCESS:
      return {
        ...state,
        isLoadingOrgs: false,
      };
    case ACTION_GET_ORG.SUCCESS:
      return {
        ...state,
        isLoadingOrgs: false,
        currentOrgId: action.payload.id,
      };
    case ACTION_SET_CURRENT_ORG:
      return {
        ...state,
        currentOrgId: action.payload,
      };
    case ACTION_RESET_CURRENT_ORG.SUCCESS:
      return {
        ...state,
        isLoadingOrgs: false,
        currentOrgId: action.payload,
      };
    case ACTION_GET_ORG_TEAM.SUCCESS:
      return {
        ...state,
        isLoadingOrgs: false,
        orgTeam: action.payload,
      };
    default:
      return state;
  }
};
