import { getCurrentUser } from "app-redux/selectors/accounts.selector";
import { getDefaultWorkspace } from "app-redux/selectors/licensingAccounts.selector";
import { getAccountsIdsWithInviteMFeEnabled, getIsInviteMemberMFeEnabled } from "app-redux/selectors/rollout.selector";
import { store } from "app-redux/store";
import { AppConstants } from "core/app.constants";
import { isUserPrivileged } from "core/common-methods";
import { ContractDTO } from "core/contracts/models";
import { RoutePaths, history } from "core/history";
import find from "lodash/find";
import get from "lodash/get";
import moment from "moment";
import qs from 'querystring';
import { SelectedPages } from "./enums";

export const getAppHostsInfo = () => {
  const qaAccountsURL = 'qa.accounts.cropwise.com';
  const qaAccountsURLUk = 'qa.accounts.syngentadigital.co.uk';
  const qaAccountsURLUk2 = 'uk-qa.accounts.cropwise.com';

  const domainMapping: any = {
    'localhost:3000': { host: 'GLOBAL', account: qaAccountsURL },
    'localhost:4000': { host: 'UK', account: qaAccountsURLUk2 },
    'localhost:5000': { host: 'UK', account: qaAccountsURLUk },
    'beta.accounts.cropwise.com': { host: 'GLOBAL', account: 'beta.accounts.cropwise.com' },
    'beta.accounts.syngentadigital.co.uk': {
      host: 'UK',
      account: 'beta.accounts.syngentadigital.co.uk',
    },
    'dev.accounts.cropwise.com': { host: 'GLOBAL', account: 'dev.accounts.cropwise.com' },
    'uk-dev.accounts.cropwise.com': { host: 'UK', account: 'uk-dev.accounts.cropwise.com' },
    'dev.accounts.syngentadigital.co.uk': { host: 'UK', account: 'dev.accounts.syngentadigital.co.uk' },
    'qa.accounts.cropwise.com': { host: 'GLOBAL', account: qaAccountsURL },
    'uk-qa.accounts.cropwise.com': { host: 'UK', account: qaAccountsURLUk2 },
    'qa.accounts.syngentadigital.co.uk': { host: 'UK', account: qaAccountsURLUk },
    'staging.accounts.cropwise.com': { host: 'GLOBAL', account: 'staging.accounts.cropwise.com' },
    'staging.accounts.syngentadigital.co.uk': {
      host: 'UK',
      account: 'staging.accounts.syngentadigital.co.uk',
    },
    'accounts.cropwise.com': { host: 'GLOBAL', account: 'accounts.cropwise.com' },
    'uk.accounts.cropwise.com': { host: 'UK', account: 'uk.accounts.cropwise.com' },
    'accounts.syngentadigital.co.uk': { host: 'UK', account: 'accounts.syngentadigital.co.uk' },
  };

  const { host } = window.location;
  return {
    host: (!!domainMapping[host] && domainMapping[host].host) || 'GLOBAL',
    account: !!domainMapping[host] && domainMapping[host].account,
    hostsList: [...new Set(Object.values(domainMapping).map((d: any) => d.host))],
  };
};

export const isUK = () => {
  const { host } = getAppHostsInfo();
  return host === 'UK';
};

export const isPermissionAccess = (permissions: any, action: any) => {
  if (typeof action === 'string') {
    return find(permissions, { action });
  }
  if (Array.isArray(action)) {
    return find(action, (actionItem) => {
      return find(permissions, { action: actionItem });
    });
  }
};

export const checkOrgOwnership = (
  authorities: any,
  actions: any,
  orgId: string,
) => {
  const scopes: any = [];
  authorities?.some((authority: any) => {
    if (authority.context === 'ORGANIZATION' && authority.id === orgId) {
      authority.permissions.forEach(
        (el: any) => el.name === 'OWNER' && scopes.push(el.scope),
      );
      return true;
    }
  });
  return actions.every((a: any) => scopes.includes(a));
};

export const filterTextForBrandName = (content: string) => {

  try {
    if (isUK()) {
      const convertBase64ToHtml = window.atob(content.replace('data:text/html;charset=utf-8;base64,', ''));
      const filteredText = convertBase64ToHtml.replace(/\bCropwise\b(?![^<]*?<\/span><\/a>)/g, '').replace(/\bcropwise\b(?![^<]*?<\/span><\/a>|<\/span><\/ins><\/a>|.)/g, '').replace(/\bCROPWISE\b(?![^<]*?<\/span><\/a>)/g, '');
      return 'data:text/html;charset=utf-8;base64,' + window.btoa(filteredText);
    }
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.log(e);
  }
  return content;
};

export function sortByKey(list: any, key: string) {
  return list.sort((a: any, b: any) => {
    const valueA = a[key].toUpperCase(); // ignore upper and lowercase
    const valueB = b[key].toUpperCase(); // ignore upper and lowercase
    if (valueA < valueB) {
      return -1;
    }
    if (valueA > valueB) {
      return 1;
    }
    // names must be equal
    return 0;
  });
}

export const getDeepActionUrl = () => {
  if (isUK()) {
    return process.env.FARM_SETTINGS_UK_URL;
  } else {
    return process.env.FARM_SETTINGS_CW_URL;
  }
};

export const getDeepActionCBUrl = () => {
  return `${window.location.href}`;
}

export const getCurrentSelectedPage = () => {
  if (window.location.pathname.includes('/plans')) return SelectedPages.PLANS
  if (window.location.pathname.includes('/members')) return SelectedPages.MEMBERS_AND_CONTACTS
  return 'N/A'
}

export const getUrlParam = (paramKey: any) => {
  try {
    const parsedQuery = qs.parse(window.location.search.replace("?", ""));
    return typeof paramKey == 'string' ? parsedQuery[paramKey] : parsedQuery;
  } catch (_e) {
    return '';
  }
}

export const redirectToActivateNewPlanDeepAction = () => {
  window.location.href = `${getDeepActionUrl()}/app/deep-actions/activate-new-plan?workspace=${getDefaultWorkspace()}&callback_uri=${getDeepActionCBUrl()}`;
};

const ID_TO_ENABLE_INVITE_MFE_ON_ALL_ACCOUNTS = 'ALL';

const getIsInviteMFeEnabledForCurrentUser = () => {
  const state = store.getState();
  const isMFeEnabled = getIsInviteMemberMFeEnabled(state);
  const currentUser = getCurrentUser(state);
  const accountsWithInviteMFeEnabled = getAccountsIdsWithInviteMFeEnabled(state)?.replace(/\s+/g, '');
  const accountsIds = accountsWithInviteMFeEnabled?.split(',');
  return isMFeEnabled && (accountsIds.includes(ID_TO_ENABLE_INVITE_MFE_ON_ALL_ACCOUNTS) || accountsIds.includes(currentUser?.id));
}

export const redirectToInviteMemberMFeOrDeepAction = (workspaceId: string) => {
  const isInviteMFeEnabledForCurrentUser = getIsInviteMFeEnabledForCurrentUser();
  if (isInviteMFeEnabledForCurrentUser) {
    history.push(RoutePaths.INVITE_MEMBERS_PAGE() + `?workspace=${workspaceId}`);
    
  } else {
    window.location.href = `${getDeepActionUrl()}/app/deep-actions/invite-members?workspace=${workspaceId}&invite_level=workspace&callback_uri=${getDeepActionCBUrl()}`;
  }
};

export const redirectToEditMemberMFeOrDeepAction = (workspaceId: string, memberId: string) => {
  // TODO: Uncomment this check when edit member flow is ready on Invite MFe
  // const isInviteMFeEnabledForCurrentUser = getIsInviteMFeEnabledForCurrentUser();
  // if(isInviteMFeEnabledForCurrentUser) {
  //   history.push(RoutePaths.INVITE_MEMBERS_PAGE() + `?workspace=${workspaceId}&member=${memberId}`);
  // }
  window.location.href = `${getDeepActionUrl()}/app/deep-actions/edit-members?workspace=${workspaceId}&invite_level=workspace&callback_uri=${getDeepActionCBUrl()}&member=${memberId}`;
}

export const arrayofIds = (data: any) => {
  let ids: any = [];
  data.forEach((el: any) => {
    return ids.push(el.id);
  });
  return ids;
};

const isContractPendingPayment = (contract: ContractDTO) => {
  return contract.waiting_for_payment;
};

const isContractActive = (contract: ContractDTO) => {
  return moment(new Date()).isBetween(contract.start, contract.end);
};


export const getContractCurrentStatus = (contract: ContractDTO) => {
  if (!isContractActive(contract)) {
    return 'inactive';
  }
  if (isContractPendingPayment(contract) && isContractActive(contract)) {
    return 'pending';
  }
  return 'active';
};

export const isUsingRBAC = (state: any = store.getState()) => {
  return !!(state.rollout.flags.isRBACVerificationEnabledOnBaseFront && get(state, 'accounts.currentUser.is_using_rbac'));
};

export const getIsAppPermitted = (appId: string) => {
  return AppConstants.PERMITTED_PRODUCT_IDS.find(app => app === appId);
}

export const getResourceAttachmentIds = (permission: string) => {
  const resourceAttachment = permission.split("#")[0];
  if (resourceAttachment.includes("crn:system") || resourceAttachment.includes("none")) {
    return null;
  }
  const workspaceId = resourceAttachment.split(":")[2].split("/")[0];
  const orgId = resourceAttachment.split(":")[2].split("/")[2];
  const propertyId = resourceAttachment.split(":")[2].split("/")[4];
  if (propertyId) {
    return {
      workspace_id: workspaceId,
      org_id: orgId,
      property_id: propertyId
    }
  }
  if (orgId) {
    return {
      workspace_id: workspaceId,
      org_id: orgId
    }
  }
  return {
    workspace_id: workspaceId,
  }
}

/**
 * add language fallback for translations
 * suppose if a key is not exist in any file, will search in fallback lanhuage file
 * @param {*} code
 * @param {*} fallback
 * @returns
 */
export const addFallbackForTranslations = (code: any, t: any, params: any = {}, fallback: any = 'en') => {
  let translation = t(code, params);
  if (translation === code) {
    translation = t(code, {
      ...params,
      lng: fallback
    });
  }
  return translation;
};

/**
 * translation wrapper
 * @param {*} translation
 * @returns
 */
export const translationWrapper = (
  translation: any,
  addKeyFromSession = true
) => {
  const { t } = translation;
  const getTranslation = (key: string, params = {}) => {
    if (key) {
      if (key.startsWith('global.')) {
        return addFallbackForTranslations(key, t, params);
      } else {
        if (addKeyFromSession) {
          const getThePageTitle =
            sessionStorage.getItem('pageIdentifierKey') || '';
          return addFallbackForTranslations(
            `${getThePageTitle}.${key}`,
            t,
            params
          );
        } else {
          return addFallbackForTranslations(key, t, params);
        }
      }
    }
    return '';
  };
  return { t: getTranslation };
};

export const checkUserPermissionWithOrgsOwnerShip = () => {
  return !isUsingRBAC() && !isUserPrivileged();
};

export const getCurrentEnv = () => {
  const currentEnv = process.env.REACT_APP_envName;
  switch (currentEnv) {
    case 'local':
      return 'qa';
    case 'production':
      return 'prod';
    default:
      return currentEnv;
  }
};
