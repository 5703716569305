import { AxiosResponse } from 'axios';
import { BasicAppDTO } from 'core/apps/models';
import { axiosRequest } from 'core/axios';
import { getHeaders } from 'core/common-methods';
import { createRequestAction, RequestAction } from 'core/utils/actionUtils';
import { PageDTO, UUID } from 'core/utils/BasicModels';
import { find, get, sortBy } from 'lodash';
import { Dispatch } from 'redux';
import { verifyRBACPermission } from 'services/rbac';
import { RBAC_ACTIONS, WORKSPACE_ACTIONS } from 'services/rbacActions';
import { getUrlParam } from 'utils/common-methods';
import { AppsStore } from '../../core/products/models';
import { WorkspaceStoreAppDTO } from '../../core/store/models';
import { IWorkspace } from '../../core/workspaces/models';
import { setCurrentWorkspace } from './licensingAccounts.actions';
import { fetchAllOrgs } from './org.actions';

export const ACTION_GET_STORE_APP_BY_ID: RequestAction = createRequestAction('GET_STORE_APP_BY_ID_REQUEST');
export const ACTION_GET_STORE_APP_PER_WORKSPACE: RequestAction = createRequestAction('GET_STORE_APP_PER_WORKSPACE');
export const ACTION_GET_APP__PUBLIC_INFO_BY_ID: RequestAction = createRequestAction('GET_APP_PUBLIC_INFO_BY_ID_REQUEST');

export const getStoreAppById = (appId: UUID, licensingAccountId: UUID) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_GET_STORE_APP_BY_ID.REQUEST });

    const storeAppById = await axiosRequest
      .get<any, AxiosResponse<BasicAppDTO>>(`/v2/store/apps/${appId}`,
      {
        headers: {
          ...getHeaders().common,
          ...getHeaders().language
        },
        params: {
          licensing_account_id: licensingAccountId,
          include_expired_contracts: true,
        },
      });

    const data = storeAppById.data;

    dispatch({ type: ACTION_GET_STORE_APP_BY_ID.SUCCESS, payload: data });

    return data;
  } catch (e) {
    dispatch({ type: ACTION_GET_STORE_APP_BY_ID.ERROR, payload: e });
    throw e;
  }
};

const validateOrgList = async (workspaces: any) => {
  try {
    let permissionPayload: any = [];
    workspaces.forEach((o: any) => {
      permissionPayload = permissionPayload.concat(WORKSPACE_ACTIONS(o.id));
    });
    const payloadAccessCheck = {
      operations: permissionPayload,
    };
    const data: any = await verifyRBACPermission(payloadAccessCheck);
    const allowed_operations: any = get(data, "data.allowed_operations");
    return workspaces.filter((org: any) => {
      const isWorkspaceDeletePermission = !!find(allowed_operations, {
        action: RBAC_ACTIONS.workspace.delete,
      });
      const isWorkspaceWritePermission = !!find(allowed_operations, {
        action: RBAC_ACTIONS.workspace.write,
      });

      org.rbacPermissions = {
        [RBAC_ACTIONS.workspace.delete]: isWorkspaceDeletePermission,
        [RBAC_ACTIONS.workspace.write]: isWorkspaceWritePermission,
      };
      return true;
    });
  } catch (e) {
    return workspaces;
  }
};

export const getStoreAppsPerWorkspace = () => async (dispatch: any): Promise<any> => {
  try {
    dispatch({ type: ACTION_GET_STORE_APP_PER_WORKSPACE.REQUEST });

    const storeAppPerWorkspace = await axiosRequest
        .get<any, AxiosResponse<PageDTO<WorkspaceStoreAppDTO>>>(`/v2/store/workspaces/apps?include_default_workspace=true`,
      {
        headers: {
          ...getHeaders().common,
          ...getHeaders().language
        },
      });

    const data = storeAppPerWorkspace.data.content;
    let workspaces: IWorkspace[] = data.map((storeWorkspace) => {
      return {
        name: storeWorkspace.name,
        id: storeWorkspace.workspace_id,
        products: storeWorkspace.workspace_apps as unknown as AppsStore,
        metadata: {},
        contracts: [],
        start: '',
        account_id: '',
      } as unknown as IWorkspace;
    });
    
    
    if (workspaces.length) {
      workspaces = await validateOrgList(workspaces);
      workspaces = sortBy(workspaces, ["name"]);
    }
    
    dispatch({
      type: ACTION_GET_STORE_APP_PER_WORKSPACE.SUCCESS,
      payload: workspaces,
    });
    // Set the workspace from url
    const getWsFromUrl: any = getUrlParam("workspace");
    if (getWsFromUrl) {
      dispatch(setCurrentWorkspace(getWsFromUrl));
    }
    // Fetch all orgs from workspaces
    const workspacesIds = workspaces.map((workspace: IWorkspace) => workspace.id);
    dispatch(fetchAllOrgs(workspacesIds));
    return data;
  } catch (e) {
    dispatch({ type: ACTION_GET_STORE_APP_PER_WORKSPACE.ERROR, payload: e });
    throw e;
  }
};

export const getAppPublicInfoById = (appId: UUID) => async (dispatch: Dispatch): Promise<BasicAppDTO> => {
  try {
    dispatch({ type: ACTION_GET_APP__PUBLIC_INFO_BY_ID.REQUEST });

    const storeAppById = await axiosRequest
      .get<any, AxiosResponse<BasicAppDTO>>(`/v2/apps/${appId}/info`,
      {
        headers: {
          ...getHeaders().common,
          ...getHeaders().language
        },
      });

    const data = storeAppById.data;

    dispatch({ type: ACTION_GET_APP__PUBLIC_INFO_BY_ID.SUCCESS, payload: data });

    return data;
  } catch (e) {
    dispatch({ type: ACTION_GET_APP__PUBLIC_INFO_BY_ID.ERROR, payload: e });
    throw e;
  }
};
