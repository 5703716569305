import { history } from 'core/history';
import { OrganizationDTO } from 'core/orgs/models';
import { matchPath } from 'react-router';
import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { OrgsState } from '../reducers/orgs.reducer';

export const getOrgsState = (state: AppState) => state.orgsState || {} as OrgsState;

const orgsMapToList = (orgsState: OrgsState) => Object.values(orgsState.orgs);

export const getOrgsList = createSelector(getOrgsState, orgsMapToList);
export const getOrgsMap = createSelector(getOrgsState, (orgsState: OrgsState) => orgsState.orgs);

export const hasLoadedOrgs = createSelector(getOrgsState,
    (orgsState: OrgsState) => {
      return !!orgsMapToList(orgsState).length;
    },
);

export const selectIsLoadingOrgs = createSelector(getOrgsState,
    (orgsState) => orgsState.isLoadingOrgs);

const orgsStateToCurrentOrgId = (orgsState: OrgsState) => orgsState.currentOrgId ;

const getCurrentOrgIdFromPath = (path: string) => {
  const pathParams = matchPath(history.location.pathname, {
        path: [
            '/orgs/:orgId/*',
        ],
        exact: true,
        strict: true,
    })?.params as { orgId?: string };

  return (pathParams && pathParams.orgId) ? pathParams.orgId : undefined;
};

export const getCurrentOrgIdFromStateOrPath = (orgsState: OrgsState) => {
  const stateOrgId = orgsStateToCurrentOrgId(orgsState);
  if (!stateOrgId) {
    return getCurrentOrgIdFromPath(history.location.pathname);
  }
  return stateOrgId;
};
export const getCurrentOrgId = createSelector(getOrgsState, getCurrentOrgIdFromStateOrPath);

export const getCurrentOrg = createSelector(getOrgsState,
    (orgsState: OrgsState) => {
      const currentOrgId = getCurrentOrgIdFromStateOrPath(orgsState);
      if (currentOrgId) {
        return orgsState.orgs.find((org: OrganizationDTO) => org.id === currentOrgId);
      }
    },
);

export const getOrgTeam = createSelector(getOrgsState,
    (orgsState: OrgsState) => orgsState.orgTeam || []);

export const getCurrentWorkspaceOrgs = createSelector(
  (state => state.workspaces),
  getOrgsState,
  ({ currentWorkspaceId }, {orgs}) => orgs.filter(org => org.workspace_id === currentWorkspaceId)
)
