export const RBAC_ACTIONS = {
  workspace: {
    write: 'workspace:write',
    new: 'workspace:new',
    delete: 'workspace:delete',
    read: 'workspace:read'
  },
  fields: {
    new: 'fields:new',
    delete: 'fields:delete',
    write: 'fields:write',
    read: 'fields:read'
  },
  organization: {
    new: 'organization:new',
    read: 'organization:read',
    write: 'organization:write',
    delete: 'organization:delete'
  },
  member: {
    new: 'members:new',
    read: 'members:read',
    write: 'members:write'
  },
  contract: {
    new: 'contracts:new'
  },
  channel_level: {
    read: 'channel_level:read',
    write: 'channel_level:write',
    delete: 'channel_level:delete'
  },
  role: {
    write: 'role:write'
  }
};
export const WORKSPACE_ACTIONS = (workspace_id = '') => {
  return [{
    action: 'workspace:read',
    resource: `crn:workspace:${workspace_id}`
  }, {
    action: 'workspace:write',
    resource: `crn:workspace:${workspace_id}`
  }, {
    action: 'workspace:delete',
    resource: `crn:workspace:${workspace_id}`
  }];
};