export const AppConstants = {
  TERM_AND_CONDITIONS: '',
  DATE_FORMATS: {
    YYYY_DASH_MM_DASH_DD_WITH_TIME: `yyyy-MM-dd'T'HH:mm:ss'Z'`,
    MMM_SPACE_YEAR: `MMM yyyy`,
    YYYY_DASH_MM_DASH_DD: 'YYYY-MM-DD',
    YYYY_DASH_MM_DASH_DDTHH_COLON_mm_COLON_ss: 'YYYY-MM-DDTHH:mm:ss',
    dddd_SPACE_DD_SPACE_MMM: 'dddd DD MMM',
  },
  VALIDATIONS: {
    MOBILE_DIGITS_LENGTH: 20,
    MIN_MOBILE_DIGITS_LENGTH: 8,
    NAME_MAX_LENGTH: 50,
    COMMENTS_MAX_LENGTH: 100,
    ZIP_CODE_MAX_LENGTH: 15,
    CITY_MAX_LENGTH: 50,
    COUNTRY_MAX_LENGTH: 25,
    STATE_MAX_LENGTH: 50,
    FIELD_NAME_MAX_LENGTH: 25,
    EIN_MAX_LENGTH: 20,
    ADDRESS_MAX_LENGTH: 100,
    SEASONS_NAME_LENGTH: 22,
  },
  EMAIL_MAX_LENGTH: 352,
  NAME_MAX_LENGTH: 255,
  PASSWORD_MAX_LENGTH: 50,
  ADD_FIELDS: {
    SEARCH_NAME_MAX_LENGTH: 25,
  },
  DATE_PERIOD: {
    DATE_FORMAT: 'MMM DD, YYYY',
    NINE: 9,
    ONE: 1,
    SIX: 6,
    THREE_SIXTY_FIVE: 365,
    THREE_ZERO: 30,
    YEARS: 'years',
    MONTHS: 'months',
    DAYS: 'days',
  },
  CLOCK_FORMAT: {
    FORMAT_12: 'FORMAT_12',
    FORMAT_24: 'FORMAT_24',
  },
  getTimeConstants: (t: any) => ({
    [AppConstants.CLOCK_FORMAT.FORMAT_12]: [
      '12:00 PM',
      '03:00 PM',
      '06:00 PM',
      '09:00 PM',
      '12:00 AM',
      '03:00 AM',
      '06:00 AM',
      '09:00 AM',
    ],
    [AppConstants.CLOCK_FORMAT.FORMAT_24]: [
      t('MIDDAY'),
      '15:00',
      '18:00',
      '21:00',
      t('MIDNIGHT'),
      '03:00',
      '06:00',
      '09:00',
    ],
  }),
  TASK_STATUS: (status: string, primaryStatus: string, t: any) => {
    const mapping: any = {
      PENDING: { text: t('Pending'), color: '#707374' },
      DELAYED: { text: t('Delayed'), color: '#F74141' },
    };
    return mapping[status] || primaryStatus;
  },
  GRAPH_DEFAULT_COLORS: ['#EB8205', '#F9C43D', '#00A0BE'],
  SUPPORTED_COUNTRIES: [
    { name: 'Argentina', value: 'AR' },
    { name: 'Austria', value: 'AT' },
    { name: 'Belarus', value: 'BY' },
    { name: 'Belgium', value: 'BE' },
    { name: 'Brazil', value: 'BR' },
    { name: 'Bulgaria', value: 'BG' },
    { name: 'Canada', value: 'CA' },
    { name: 'Chile', value: 'CL' },
    { name: 'Colombia', value: 'CO' },
    { name: 'Croatia', value: 'HR' },
    { name: 'Czechia', value: 'CZ' },
    { name: 'Denmark', value: 'DK' },
    { name: 'Egypt', value: 'EG' },
    { name: 'Estonia', value: 'EE' },
    { name: 'France', value: 'FR' },
    { name: 'Georgia', value: 'GA' },
    { name: 'Germany', value: 'DE' },
    { name: 'Greece', value: 'GR' },
    { name: 'Guinea-Bissau', value: 'GW' },
    { name: 'Hungary', value: 'HU' },
    { name: 'India', value: 'IN' },
    { name: 'Ireland', value: 'IE' },
    { name: 'Italy', value: 'IT' },
    { name: 'Kazakhstan', value: 'KZ' },
    { name: 'Latvia', value: 'LV' },
    { name: 'Lithuania', value: 'LT' },
    { name: 'Mexico', value: 'MX' },
    { name: 'Moldova', value: 'MD' },
    { name: 'Netherlands', value: 'NL' },
    { name: 'New Zealand', value: 'NZ' },
    { name: 'Panama', value: 'PA' },
    { name: 'Paraguay', value: 'PY' },
    { name: 'Peru', value: 'PE' },
    { name: 'Philippines', value: 'PH' },
    { name: 'Plurinational State of Bolivia', value: 'BO' },
    { name: 'Poland', value: 'PL' },
    { name: 'Portugal', value: 'PT' },
    { name: 'Romania', value: 'RO' },
    { name: 'Russian Federation', value: 'RU' },
    { name: 'Rwanda', value: 'RW' },
    { name: 'Slovakia', value: 'SK' },
    { name: 'Slovenia', value: 'SI' },
    { name: 'South Africa', value: 'ZA' },
    { name: 'Spain', value: 'ES' },
    { name: 'Sweden', value: 'SE' },
    { name: 'Thailand', value: 'TH' },
    { name: 'Turkey', value: 'TR' },
    { name: 'Ukraine', value: 'UA' },
    { name: 'United Kingdom', value: 'GB' },
    { name: 'United States', value: 'US' },
    { name: 'Uruguay', value: 'UY' },
    { name: 'Uzbekistan', value: 'UZ' },
    { name: 'Vietnam', value: 'VN' },
    { name: 'Zambia', value: 'ZM' },
    { name: 'Zimbabwe', value: 'ZW' }
  ],
  LINKS: {
    CROPWISE: 'https://www.cropwise.com/',
    LEGAL_DOCUMENTS: '/app/legaldocs/US',
    UK:'https://www.syngentadigital.co.uk/',
    SYNGENTA_DIGITAL: 'https://www.syngentadigital.co.uk',
  },
  PERMITTED_PRODUCT_IDS : [
    process.env.REACT_APP_PROTECTOR_ID,
    process.env.REACT_APP_IMAGERY_ID,
    process.env.REACT_APP_FARM_SETTINGS_ID,
    process.env.REACT_APP_FINANCIALS_ID,
  ],
  TRIGGER_DELETION_FLOW : {
    project:{"id": "427019"},
    summary: "CWS - User Data Deletion Request - Review",
    labels: ["data-accounts-deletion-flow"],
    issuetype: {"name": "Task"}
  },
  FARM_SETTINGS_ID: "43524f50-5749-5345-4253-434149554249"
};
