import { combineReducers } from 'redux';
import { accountsReducer, AccountsState } from './accounts.reducer';
import { workspacesReducer, WorkspacesState } from './workspacesReducer';
import { OrgsState, orgsStateReducer } from './orgs.reducer';
import { productsReducer, ProductsState } from './products.reducers';
import { ProfileEdit, profileReducer } from './profile.reducer';
import { rolloutReducer, RolloutState } from './rollout.reducer';
import { workspaceReducer, WorkspaceState } from './workspace.reducer';

export interface AppState {
  accounts: AccountsState;
  orgsState: OrgsState;
  products: ProductsState;
  workspaces: WorkspacesState;
  profile: ProfileEdit;
  workspace: WorkspaceState;
  rollout: RolloutState;
}

export const rootReducer = combineReducers<AppState>({
  accounts: accountsReducer,
  orgsState: orgsStateReducer,
  products: productsReducer,
  workspaces: workspacesReducer,
  profile: profileReducer,
  workspace: workspaceReducer,
  rollout: rolloutReducer,
});
