import * as Sentry from '@sentry/react';
import { Route } from 'react-router-dom';
import { history } from 'core/history';
import { store } from 'app-redux/store';
import { isQA } from 'app.constant';

export const SENTRY_KEY = process.env.REACT_APP_SENTRY_DSN ?? '';
const APP_ENVIRONMENT = process.env.REACT_APP_envName ?? '';
const SAMPLE_RATE = process.env.REACT_APP_SENTRY_SAMPLE_RATE ?? 1.0;
const NORMALIZE_DEPTH = 5;

export const SentryRoute = Sentry.withSentryRouting(Route);

if(isQA){
  console.log(SENTRY_KEY, APP_ENVIRONMENT, SAMPLE_RATE, 'Sentry env variables')
}

export const SENTRY_CONFIG: Sentry.BrowserOptions = {
  dsn: SENTRY_KEY,
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history })
  ],
  tracesSampleRate: Number(SAMPLE_RATE),
  // release: `v${APP_VERSION}`,
  environment: APP_ENVIRONMENT,
  normalizeDepth: NORMALIZE_DEPTH,
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb?.category?.startsWith('ui')) {
      const target = hint?.event.target;
      const dataTestId = target.dataset.testid;
      const customMessage = `[data-testid="${dataTestId}"]${breadcrumb.message}`;
      breadcrumb.message = dataTestId ? customMessage : breadcrumb.message;
    }
    return breadcrumb;
  },
  beforeSend(event) {
    const state = store.getState()
    const currentWorkspaceId = state.workspaces.currentWorkspaceId
    const workspaceList = state.workspaces.workspaces
    const currentWorkspace = workspaceList?.find((workspace) => workspace.id === currentWorkspaceId)
    const currentOrgId = state.orgsState.currentOrgId
    const orgList = state.orgsState.orgs
    const currentOrg = orgList?.find((org) => org.id === currentOrgId)

    if(currentWorkspaceId) {
      event.tags = {
        workspace_id: currentWorkspace?.id ?? currentWorkspaceId,
        workspace_name: currentWorkspace?.name
      };
    }

    if(currentOrgId) {
      event.tags = {
        org_id: currentOrg?.id ?? currentOrgId,
        org_name: currentOrg?.name
      };
    }

    return event;
  }
};