import axios from 'axios';
import { applyAxiosInterceptors } from 'pages/main-container/AxiosResponseInterceptor';
import { getAppHostsInfo } from 'utils/common-methods';
import { isWebUri } from 'valid-url';

export const headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
  'authorization': 'Basic ' + btoa(`${process.env.CLIENT_ID}:`),
};

const urlToOverride = localStorage.getItem('baseURL') || '';
let baseURL = process.env.API_URL;

if (isWebUri(urlToOverride)) {
  baseURL = urlToOverride;
}

export const axiosRequest = applyAxiosInterceptors(axios.create({
  baseURL,
}));

export const fetchRequest = (path: string, config: RequestInit) => {
  const { account } = getAppHostsInfo();
  return new Request(`https://${account}${path}`, config);
};
