import { applyMiddleware, compose, createStore } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { rootReducer } from './reducers';
import { enableDebug } from 'app.constant';

const reduxDevTool = '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__';

const composeEnhancers =
// tslint:disable-next-line:strict-type-predicates
    (typeof window === 'object' && enableDebug && (window as any)[reduxDevTool]) || compose;

const middlewares = [thunk];
if (enableDebug) {
  middlewares.push(logger as any);
}

const enhancer = composeEnhancers(applyMiddleware(...middlewares));
export const store = createStore(rootReducer, enhancer);
