import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const RoutePaths = {
  WORKSPACES: (id='') =>  id == '' ? '/app/workspaces' : `/app/workspaces?workspace=${id}`,
  AUTH: () => '/app/authenticate',
  HOME: () => '/',
  ORGS: () => '/orgs',
  LEGAL: (countryKey: string = ':countryKey') => `/app/legaldocs/${countryKey}`,
  PRIVACY: () => '/app/privacynotice/:country?',
  TEMP_PRIVACY: () => '/app/privacypolicy',
  PROTECTOR_MARKETING: () => '/app/marketing-page-protector',
  PROFILE: () => `/app/profile`,
  OVERVIEW: () => `/app/workspaces`,
  PRODUCTS: (accountId: string = ':accountId') => `/app/workspaces/${accountId}/products`,
  MEMBERS: (_accountId: string = ':accountId') => `/app/workspaces/members`,
  PLANS: (_accountId: string = ':accountId') => `/app/workspaces/plans`,
  QUOTA_MANAGEMENT: () => `/app/quota`,
  HIERARCHY_MANAGEMENT: () => `/app/hierarchy`,
  INVITE_MEMBERS_PAGE: () => `/app/invite-members`
};

export const PublicRoutes = [RoutePaths.AUTH(), RoutePaths.HOME(), RoutePaths.LEGAL(), RoutePaths.PRIVACY(), RoutePaths.PROTECTOR_MARKETING(), RoutePaths.TEMP_PRIVACY()];
