import "core-js/stable";
import "regenerator-runtime/runtime";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import MainApp from "./App";
import { store } from "./app-redux/store";
import { AppLoader } from "./core/AppLoader";
import { axiosRequest } from "core/axios";
import { getAccessToken } from "core/common-methods";
import { getCurrentEnv } from "utils/common-methods";
import "./i18n";
import { CWEnv, initCWElements } from "@cw-elements/config";
import { initMfes } from "@cw-elements/mfe-setup/init";
import { PublicRoutes, history } from "core/history";
import { matchPath } from "react-router";
import { SENTRY_CONFIG, SENTRY_KEY } from "sentry.config";
import { initAnalytics } from "@cw-elements/analytics-provider";
import { isLocal } from "app.constant";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
if (!isLocal && SENTRY_KEY) {
  Sentry.init(SENTRY_CONFIG);
}
if (process.env.REACT_APP_AMPLITUDE_KEY) {
  initAnalytics({
    clientId: process.env.REACT_APP_AMPLITUDE_KEY,
    engagement: true
  });
}
const initClients = () => {
  let tokens;
  try {
    tokens = JSON.parse(getAccessToken());
  } catch (ignored) {
    tokens = null;
  }
  initCWElements({
    environment: (getCurrentEnv() as CWEnv) ?? "qa",
    axiosInstance: axiosRequest,
    token: tokens?.access_token,
    hostAppId: process.env.ACCOUNTS_APP_ID
  });
};
initClients();
export const initMfesAccountsManagement = async () => {
  await initMfes({
    "quota-mgt": `${process.env.REACT_APP_CW_ELEMENTS_MFES}/quota-mgt`,
    "hierarchy-mgt": `${process.env.REACT_APP_CW_ELEMENTS_MFES}/hierarchy-mgt`,
    "members-mgt": `${process.env.REACT_APP_CW_ELEMENTS_MFES}/members-mgt`,
    "invite-members": `${process.env.REACT_APP_CW_ELEMENTS_MFES}/invite-members`,
    "contacts-mgt": `${process.env.REACT_APP_CW_ELEMENTS_MFES}/contacts-mgt`,
    "edit-member": `${process.env.REACT_APP_CW_ELEMENTS_MFES}/edit-member`,
    "member-list": `${process.env.REACT_APP_CW_ELEMENTS_MFES}/member-list`,
    "topbar-navigation": `${process.env.REACT_APP_CW_ELEMENTS_MFES}/topbar-navigation`
  });
};
(async function loadAppLoader() {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHY_DARKLY_ID ?? ""
  });

  // Only loads mfes on private pages of the app, as all mfes are private
  if (!PublicRoutes.filter((router: string) => matchPath(history.location.pathname, {
    path: router,
    exact: true
  })).length) {
    await initMfesAccountsManagement();
  }
  const myapp = new AppLoader();
  const isRedirecting = await myapp.load();
  if (!isRedirecting) {
    ReactDOM.render(<Sentry.ErrorBoundary showDialog>
        <Provider store={store}>
          <LDProvider>
            <MainApp />
          </LDProvider>
        </Provider>
      </Sentry.ErrorBoundary>, document.getElementById("root"));
  }
})();