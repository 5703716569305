import { AxiosError, AxiosInstance } from 'axios';
import { initOAuth2Flow, logout } from '../../app-redux/actions/login.actions';
import { store } from '../../app-redux/store';
export const ACTION_RESPONSE_ERROR_INTERCEPTOR = 'RESPONSE_INTERCEPTOR_REQUEST';
export interface ErrorMessageDTO {
  error_description?: string;
  timestamp: string;
  status: number;
  error: string;
  code: string;
  message: string;
  path: string;
}
const axiosResponseInterceptor = (error: AxiosError<ErrorMessageDTO>) => {
  const {
    dispatch
  } = store;
  if (error.response && error.response.data && error.response.status === 401) {
    const errorDescription = error.response.data.error_description;
    if (errorDescription && errorDescription.startsWith('Access token expired')) {
      logout()(dispatch);
      initOAuth2Flow();
    }
    dispatch({
      type: ACTION_RESPONSE_ERROR_INTERCEPTOR,
      payload: error.response.data
    });
  }
  return Promise.reject(error);
};
export const applyAxiosInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.response.use(response => response, axiosResponseInterceptor);
  return axiosInstance;
};