import { AppsStore } from 'core/products/models';
import { PayloadAction } from 'core/utils/actionUtils';
import { USER_PRODUCTS } from '../actions/products.actions';

export interface ProductsState {
  products?: AppsStore[];
  loading?: boolean;
  error?: any;
}

const initialState: ProductsState = {};

export const productsReducer = (
  state: ProductsState = initialState,
  action: PayloadAction<any>,
): ProductsState => {
  switch (action.type) {
    case USER_PRODUCTS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_PRODUCTS.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_PRODUCTS.SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload,
      };
    default:
      return state;
  }
};
