import { IPictureDTO, IUserAccount } from 'core/accounts/models';
import { PayloadAction } from 'core/utils/actionUtils';
import { USER_PROFILE_EDIT, UPLOAD_PHOTO_REQUEST, DELETE_PHOTO_REQUEST } from '../actions/profile.actions';

export interface ProfileEdit {
  userEdited?: IUserAccount;
  photoAccount?: IPictureDTO;
  loading?: boolean;
  error?: any;
}

const initialState: ProfileEdit = {};

export const profileReducer = (
  state: ProfileEdit = initialState,
  action: PayloadAction<any>,
): ProfileEdit => {
  switch (action.type) {
    case USER_PROFILE_EDIT.REQUEST:
    case UPLOAD_PHOTO_REQUEST.REQUEST:
    case DELETE_PHOTO_REQUEST.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_PROFILE_EDIT.ERROR:
    case UPLOAD_PHOTO_REQUEST.ERROR:
    case DELETE_PHOTO_REQUEST.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_PROFILE_EDIT.SUCCESS:
      return {
        ...state,
        userEdited: action.payload,
        loading: false,
      };
    case UPLOAD_PHOTO_REQUEST.SUCCESS:
      return {
        ...state,
        photoAccount: action.payload,
        loading: false,
      }
    case DELETE_PHOTO_REQUEST.SUCCESS:
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
};
