import { AxiosResponse } from 'axios';
import { axiosRequest } from 'core/axios';
import { getHeaders } from 'core/common-methods';
import { createRequestAction, RequestAction } from 'core/utils/actionUtils';
import { Dispatch } from 'redux';
import { UUID } from '../../core/utils/BasicModels';

export const USER_PRODUCTS: RequestAction = createRequestAction('GET_USER_PRODUCTS_REQUEST');

export const fetchUserProducts =
  (accountId?: UUID) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: USER_PRODUCTS.REQUEST });
      const products = await axiosRequest.get<any, AxiosResponse<any>>(
        `/v2/store/apps`,
        {
          headers: {
            ...getHeaders().common,
            ...getHeaders().language
          },
          params: {
            licensing_account_id: accountId,
          },
        },
      );

      dispatch({ type: USER_PRODUCTS.SUCCESS, payload: products.data.content });
      return products.data.content;
    } catch (e) {
      dispatch({ type: USER_PRODUCTS.ERROR, payload: e });
      throw e;
    }
  };
