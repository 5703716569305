import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { AccountsState } from '../reducers/accounts.reducer';

export const getAccountsState = (state: AppState) => state.accounts || {} as AccountsState;

export const getCurrentUser = createSelector(getAccountsState,
    (accountsState) => accountsState.currentUser);
export const getInvitedAccount = createSelector(getAccountsState,
    (accountsState) => accountsState.invitedAccount);
export const getCurrentUserLoadingStatus = createSelector(getAccountsState,
    (accountsState) => !!accountsState.loading);
