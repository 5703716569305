import { initRollout } from 'app-redux/actions/rollout.actions';
import { getCurrentUser } from 'app-redux/selectors/accounts.selector';
import { IUserAccount } from 'core/accounts/models';
import { isAccessTokenAvailable } from 'core/common-methods';
import { history } from 'core/history';
import { ThemeProvider } from 'core/theme';
import get from 'lodash/get';
import React, { Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { Router, Switch } from 'react-router';
import styled from 'styled-components';
import './i18n';
import './main.less';
import { notification } from 'antd';
import { useGlobalAnalytics } from 'utils/useGlobalAnalytics.hook';
import * as Sentry from "@sentry/react";
import { SentryRoute } from 'sentry.config';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { setUserId } from '@cw-elements/analytics-provider';
export const StyledRoot = styled.div`
  font-family: ${props => get(props, 'theme.font.family')};
  height: 100%;
  min-height: 100%;
  * {
    box-sizing: border-box;
  }
`;
interface IAppComponentProps {
  currentUser?: IUserAccount;
  initRollout?: (configs: any) => Promise<any>;
}
const AsyncMainContainer = React.lazy(() => import('pages/main-container'));
notification.config({
  prefixCls: 'syngenta-ant-notification'
});
export const AppComponent: React.FC<IAppComponentProps> = props => {
  const track = useGlobalAnalytics();
  const ldClient = useLDClient();
  useEffect(() => {
    if (props.initRollout) {
      if (isAccessTokenAvailable() && props.currentUser) {
        const {
          id
        } = props.currentUser;
        props.initRollout({
          userContext: {
            id
          }
        });
      } else {
        props.initRollout({
          userContext: {}
        });
      }
    }
    if (ldClient && props.currentUser) {
      ldClient.identify({
        kind: 'user',
        key: props.currentUser.id,
        email: props.currentUser.email
      });
    }
    if (props.currentUser) {
      setUserId(props.currentUser.id);
      track('Accounts - Opened Accounts');
    }
  }, [props.currentUser, ldClient]);
  return <ThemeProvider data-sentry-element="ThemeProvider" data-sentry-component="AppComponent" data-sentry-source-file="App.tsx">
            <StyledRoot data-sentry-element="StyledRoot" data-sentry-source-file="App.tsx">
                <Router history={history} data-sentry-element="Router" data-sentry-source-file="App.tsx">
                    <Suspense fallback={<></>} data-sentry-element="Suspense" data-sentry-source-file="App.tsx">
                        <Switch data-sentry-element="Switch" data-sentry-source-file="App.tsx">
                            <SentryRoute path="/" component={AsyncMainContainer} data-sentry-element="SentryRoute" data-sentry-source-file="App.tsx" />
                        </Switch>
                    </Suspense>
                </Router>
            </StyledRoot>
        </ThemeProvider>;
};
const mapStateToProps = (state: any) => {
  return {
    currentUser: getCurrentUser(state)
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  initRollout: (configs: any) => dispatch(initRollout(configs))
});
export default connect(mapStateToProps, mapDispatchToProps)(Sentry.withProfiler(AppComponent));