/**
 * App Loader : Before Loading App in the dom, we need to load few meta data in our system.
 * App Loader will be used to control the sequence in which things are loaded.
 * 1. We need to load language resources from Google before we start the app.
 * 2. We need to determine if user is logged in or not.
 */
import { isAccessTokenAvailable } from 'core/common-methods';
import { history, PublicRoutes } from 'core/history';
import { initOAuth2Flow } from '../app-redux/actions/login.actions';
import { matchPath } from 'react-router-dom';

class AppLoader {

  public async redirectAsPerLoggedInStatus() {
    if (!isAccessTokenAvailable() && !(PublicRoutes.filter((router: string) => matchPath(history.location.pathname, { path: router, exact: true })).length)) {
      initOAuth2Flow(history.location.pathname);
      return true;
    }
    return false;
  }
  public async load() {
    const redirectStatus = await this.redirectAsPerLoggedInStatus();
    if (redirectStatus) {
      return redirectStatus;
    }

    return false;
  }
}

export { AppLoader };
