import { AxiosResponse } from 'axios';
import { axiosRequest } from 'core/axios';
import { getHeaders } from 'core/common-methods';
import { createRequestAction, RequestAction } from 'core/utils/actionUtils';
import {
  Workspace,
  WorkspaceEdit,
  WorkspaceResponse,
} from 'core/workspace/models';
import { Dispatch } from 'redux';
import { UUID } from '../../core/utils/BasicModels';

export const WORKSPACE: RequestAction = createRequestAction('POST_NEW_WORKSPACE');
export const DEL_WORKSPACE: RequestAction = createRequestAction('DELETE_WORKSPACE');
export const EDIT_WORKSPACE: RequestAction = createRequestAction('EDIT_WORKSPACE');
export const GET_WORKSPACE: RequestAction = createRequestAction('GET_WORKSPACE');

const baseURL = process.env.API_URL;

export const createNewWorkspace =
  (newWorkspace: Workspace) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: WORKSPACE.REQUEST });
      const endpoint = '/v2/workspaces';
      const workspace = await axiosRequest.post<any, AxiosResponse<WorkspaceResponse>>(
        endpoint,
        newWorkspace,
        {
          headers: getHeaders().common,
          baseURL,
        },
      );

      dispatch({ type: WORKSPACE.SUCCESS, payload: workspace.data });
      return workspace.data;
    } catch (e) {
      dispatch({ type: WORKSPACE.ERROR, payload: e });
      throw e;
    }
  };

export const editWorkspace =
  (workspace: WorkspaceEdit) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: EDIT_WORKSPACE.REQUEST });
      const workspaceEdited = await axiosRequest.put<any, AxiosResponse<WorkspaceResponse>>(
        `/v2/workspaces/${workspace.id}`,
        workspace,
        {
          headers: getHeaders().common,
          baseURL
        },
      );

      dispatch({ type: EDIT_WORKSPACE.SUCCESS, payload: workspaceEdited.data });

      return workspaceEdited.data;
    } catch (e) {
      dispatch({ type: EDIT_WORKSPACE.ERROR, payload: e });
      throw e;
    }
  };

export const deleteWorkspace =
  (workspaceId: UUID) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: DEL_WORKSPACE.REQUEST });
      const delWorkspace = await axiosRequest.delete<any, AxiosResponse>(
        `/v2/workspaces/${workspaceId}`,
        {
          headers: getHeaders().common,
          baseURL
        },
      );

      dispatch({ type: DEL_WORKSPACE.SUCCESS, payload: workspaceId });
      return delWorkspace.data;
    } catch (e) {
      dispatch({ type: DEL_WORKSPACE.ERROR, payload: e });
      throw e;
    }
  };

export const getWorkspaceById =
    (workspaceId: UUID) => async (dispatch: Dispatch) => {
      try {
        dispatch({ type: GET_WORKSPACE.REQUEST });
        const workspace = await axiosRequest.get<any, AxiosResponse>(
                `/v2/workspaces/${workspaceId}`,
          {
            headers: getHeaders().common,
            baseURL
          },
            );

        dispatch({ type: GET_WORKSPACE.SUCCESS, payload: workspace.data });
        return workspace.data;
      } catch (e) {
        dispatch({ type: GET_WORKSPACE.ERROR, payload: e });
        throw e;
      }
    };
