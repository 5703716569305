import { IWorkspace } from 'core/workspaces/models';
import { PayloadAction } from 'core/utils/actionUtils';
import { UUID } from 'core/utils/BasicModels';
import {
  ACTION_RESET_CURRENT_WORKSPACE, ACTION_SET_CURRENT_WORKSPACE, WORKSPACES,
} from '../actions/licensingAccounts.actions';
import { ACTION_GET_STORE_APP_PER_WORKSPACE } from '../actions/storeApps.actions';

export interface WorkspacesState {
  workspaces?: IWorkspace[];
  currentWorkspaceId?: UUID;
  loading?: boolean;
  error?: any;
}

const initialState: WorkspacesState = {};

export const workspacesReducer = (
  state: WorkspacesState = initialState,
  action: PayloadAction<any>,
): WorkspacesState => {
  switch (action.type) {
    case ACTION_GET_STORE_APP_PER_WORKSPACE?.REQUEST:
    case WORKSPACES?.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTION_GET_STORE_APP_PER_WORKSPACE?.ERROR:
    case WORKSPACES?.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ACTION_GET_STORE_APP_PER_WORKSPACE?.SUCCESS:
    case WORKSPACES?.SUCCESS:
      return {
        ...state,
        loading: false,
        workspaces: action.payload,
      };
    case ACTION_SET_CURRENT_WORKSPACE:
    case ACTION_RESET_CURRENT_WORKSPACE:
      return {
        ...state,
        currentWorkspaceId: action.payload,
      };
    default:
      return state;
  }
};
