import { AxiosResponse } from 'axios';
import {
  InviteeDTO,
  IUserAccount,
  UserAuthorityRemovalDTO,
} from 'core/accounts/models';
import {
  AuthenticationOptions,
  defaultAuthRequestParams,
  IAuthTokenResponseDTO,
} from 'core/authentication/models';
import { axiosRequest, headers } from 'core/axios';
import { clearAccessToken, getHeaders } from 'core/common-methods';
import { createRequestAction, RequestAction } from 'core/utils/actionUtils';
import qs from 'querystring';
import { Dispatch } from 'redux';
import { getAppHostsInfo } from 'utils/common-methods';
import { UUID } from '../../core/utils/BasicModels';
import { fetchUserProducts } from './products.actions';
import { getStoreAppsPerWorkspace } from './storeApps.actions';
import { setToken } from '@cw-elements/config';
import * as Sentry from '@sentry/react';

export const ACTION_USER_LOGIN: RequestAction = createRequestAction('USER_LOGIN_REQUEST');
export const ACTION_CURRENT_ACCOUNT: RequestAction = createRequestAction('CURRENT_ACCOUNT_REQUEST');
export const ACTION_INVITE_ACCOUNT: RequestAction = createRequestAction('INVITE_ACCOUNT_REQUEST');
export const ACTION_DELETE_ACCOUNT: RequestAction = createRequestAction('INVITE_DELETE_REQUEST');
export const ACTION_LOGOUT: RequestAction = createRequestAction('USER_LOGOUT_REQUEST');
export const ACTION_RETRIEVE_ACCESS_TOKEN: RequestAction = createRequestAction('RETRIEVE_USER_ACCESS_TOKEN');

export const initOAuth2Flow = (returnStateValue?: string) => {
  const returnState = returnStateValue
    ? `&state=${btoa(returnStateValue)}`
    : '';
  const { account } = getAppHostsInfo();
  window.location.href = `https://${account}/oauth/authorize?response_type=code&client_id=${AuthenticationOptions.clientId}&redirect_uri=${AuthenticationOptions.redirectUri}${returnState}`;
};

export const retrieveAccessToken = (username: string, password: string) => async (dispatch: Dispatch) => {
  const config = {
    headers,
  };

  dispatch({ type: ACTION_RETRIEVE_ACCESS_TOKEN.REQUEST });

  const authTokenRequestDTO = {
    grant_type: 'password',
    username,
    password,
  };

  try {
    const response = await axiosRequest.post<any, AxiosResponse<IAuthTokenResponseDTO>>(
        `/oauth/token`,
        qs.stringify(authTokenRequestDTO as any),
        config,
    );

    const tokens = response.data;
    dispatch({ type: ACTION_RETRIEVE_ACCESS_TOKEN.SUCCESS, payload: tokens });

  } catch (e) {
    dispatch({ type: ACTION_RETRIEVE_ACCESS_TOKEN.ERROR, payload: e });
    throw e;
  }
};

export const performLogin = (code: string) => async (dispatch: Dispatch) => {
  const config = {
    headers,
  };

  dispatch({ type: ACTION_USER_LOGIN.REQUEST });

  const authTokenRequestDTO = {
    ...defaultAuthRequestParams,
    code,
  };

  try {
    const response = await axiosRequest.post<any, AxiosResponse<IAuthTokenResponseDTO>>(
      `/oauth/token`,
      qs.stringify(authTokenRequestDTO as any),
      config,
    );

    const tokens = response.data;
    dispatch({ type: ACTION_USER_LOGIN.SUCCESS, payload: tokens });

    if (tokens.access_token) {
      setToken(tokens.access_token);
    }

    localStorage.setItem('tokens', JSON.stringify(tokens));

    // @ts-ignore
    dispatch(fetchCurrentUser());
  } catch (e) {
    dispatch({ type: ACTION_USER_LOGIN.ERROR, payload: e });
    throw e;
  }
};

export const fetchCurrentUser = () => async (dispatch: Dispatch) => {
  if(!getHeaders() && !getHeaders().common) return
  try {
    dispatch({ type: ACTION_CURRENT_ACCOUNT.REQUEST });
    const currentUser = await axiosRequest.get<any, AxiosResponse<IUserAccount>>(
      `/v2/accounts/me`,
      {
        headers: getHeaders().common,
        params: {
          attributes: 'workspaces,is_using_rbac',
        },
      },
    );

    Sentry.setUser({
      user: {
        id: currentUser.data.id,
        email: currentUser.data.email,
        name: currentUser.data.name,
        language: currentUser.data.locale
      }
    });

    dispatch({ type: ACTION_CURRENT_ACCOUNT.SUCCESS, payload: currentUser.data });
    const licensingIds: UUID[] = [];
    currentUser.data.workspaces.forEach((licensing) =>
      licensingIds.push(licensing.licensing_account_id),
    );
    // @ts-ignore
    dispatch(fetchUserProducts());
    // @ts-ignore
    dispatch(getStoreAppsPerWorkspace());

    return currentUser.data;
  } catch (e) {
    dispatch({ type: ACTION_CURRENT_ACCOUNT.ERROR, payload: e });
    throw e;
  }
};

export const logout = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_LOGOUT.REQUEST });
    clearAccessToken();
    sessionStorage.removeItem('tempTokens');
    sessionStorage.removeItem('SESSION_USER_DETAILS');
    window.location.href = "/exit";
  } catch (e) {
    dispatch({ type: ACTION_LOGOUT.ERROR, payload: e });
    throw e;
  }
};

export const inviteAccount =
  (valuesRequest: InviteeDTO) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ACTION_INVITE_ACCOUNT.REQUEST });

      const accountInvited = await axiosRequest.post(
        `/v2/accounts/invitation`,
        valuesRequest,
        {
          headers: {
            ...getHeaders().common,
            'Accept-Language': valuesRequest.locale ?? 'en',
            'Content-Language': valuesRequest.locale ?? 'en',
          },
        },
      );

      dispatch({ type: ACTION_INVITE_ACCOUNT.SUCCESS, payload: accountInvited.data });

      return accountInvited.data;
    } catch (e) {
      dispatch({ type: ACTION_INVITE_ACCOUNT.ERROR, payload: e });
    }
  };

export const deleteUserFromOrg =
  (accountId: UUID, userAuthorityRemovalDTO: UserAuthorityRemovalDTO) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ACTION_DELETE_ACCOUNT.REQUEST });

      const accountDeleted = await axiosRequest.post(
        `/v2/accounts/${accountId}/authorities/updates`,
        userAuthorityRemovalDTO,
        {
          headers: {
            ...getHeaders().common,
          },
        },
      );

      dispatch({ type: ACTION_DELETE_ACCOUNT.SUCCESS, payload: accountDeleted.data });

      return accountDeleted.data;
    } catch (e) {
      dispatch({ type: ACTION_DELETE_ACCOUNT.ERROR, payload: e });
    }
  };
