import { PayloadAction } from 'core/utils/actionUtils';
import { WorkspaceResponse } from 'core/workspace/models';
import {
  DEL_WORKSPACE,
  EDIT_WORKSPACE,
  GET_WORKSPACE,
  WORKSPACE,
} from '../actions/workspace.actions';

export interface WorkspaceState {
  workspace?: WorkspaceResponse;
  workspacesDeletedId?: string[];
  workspaceEdited?: string[];
  loading?: boolean;
  error?: any;
}

const initialState: WorkspaceState = {
  workspacesDeletedId: [],
  workspaceEdited: [],
};

export const workspaceReducer = (
  state: WorkspaceState = initialState,
  action: PayloadAction<any>,
): WorkspaceState => {
  switch (action.type) {
    case WORKSPACE.REQUEST:
    case DEL_WORKSPACE.REQUEST:
    case EDIT_WORKSPACE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case WORKSPACE.ERROR:
    case DEL_WORKSPACE.ERROR:
    case EDIT_WORKSPACE.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_WORKSPACE.SUCCESS:
    case WORKSPACE.SUCCESS:
      return {
        ...state,
        workspace: action.payload,
      };
    case DEL_WORKSPACE.SUCCESS:
      return {
        ...state,
        workspacesDeletedId: state.workspacesDeletedId!.concat(action.payload),
      };
    case EDIT_WORKSPACE.SUCCESS:
      return {
        ...state,
        workspaceEdited: state.workspaceEdited!.concat(action.payload),
      };
    default:
      return state;
  }
};
