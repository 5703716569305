import { axiosRequest } from "core/axios";
import { AxiosResponse } from "axios";
import { getHeaders, isUserPrivileged } from "core/common-methods";
import { isUsingRBAC } from "utils/common-methods";
import { RBAC_ACTIONS } from "./rbacActions";
export const verifyRBACPermission = async (postData: any) => {
  const response = {
    message: "",
    status: false,
    data: []
  };
  const rbacActions = postData.operations;
  if (isUsingRBAC()) {
    const payloadAccessCheck = {
      operations: rbacActions
    };
    try {
      const data = await axiosRequest.post<any, AxiosResponse>(`/v2/auth/verify`, payloadAccessCheck, {
        headers: getHeaders().common
      }).catch(r => r);
      if (data.status == 200) {
        response.status = true;
        response.data = data.data;
      }
      return response;
    } catch (error) {
      response.message = "Something went wrong...";
      return response;
    }
  } else {
    if (isUserPrivileged()) {
      return {
        data: {
          allowed_operations: rbacActions
        }
      };
    } else {
      return {
        data: {
          allowed_operations: []
        }
      };
    }
  }
};
export const getOrgPropertyRBACActions = (orgPropertyData: any) => {
  const {
    currentWorkspaceId,
    orgIds: allOrgs = [],
    getAllPropertiesResponse: allOrgProperty = []
  } = orgPropertyData;
  const rbacPermissionsPayload: any = {
    operations: [{
      action: RBAC_ACTIONS.member.read,
      resource: `crn:workspace:${currentWorkspaceId}`
    }]
  };
  allOrgs?.map((orgId: any) => {
    rbacPermissionsPayload.operations.push({
      action: RBAC_ACTIONS.member.read,
      resource: `crn:workspace:${currentWorkspaceId}/org/${orgId}`
    });
  });
  allOrgProperty.map((property: any) => {
    rbacPermissionsPayload.operations.push({
      action: RBAC_ACTIONS.member.read,
      resource: `crn:workspace:${currentWorkspaceId}/org/${property.org_id}/property/${property.id}`
    });
  });
  return rbacPermissionsPayload;
};