import { getFeatureFlags, SETUP_ROLLOUT } from '../actions/rollout.actions';

export interface RolloutState {
  loading?: boolean;
  error?: any;
  flags?: any;
}

const initialState: RolloutState = {
  loading: true,
  error: null,
  flags: getFeatureFlags(undefined),
};

export const rolloutReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SETUP_ROLLOUT.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SETUP_ROLLOUT.SUCCESS:
      return {
        ...state,
        loading: false,
        flags: action.payload,
      };
    case SETUP_ROLLOUT.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
