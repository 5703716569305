import { CookieStorage } from 'cookie-storage';
import jwtDecode from 'jwt-decode';

const cookieStorage = new CookieStorage();
const env = process.env.REACT_APP_envName;

export const getDomain = () => {

  if (['local', 'dev'].includes("" + env)) {
    return 'cropwise.com';
  }

  if (window.location.hostname.includes('cropwise.com')) {
    return 'cropwise.com';
  } else {
    return 'syngentadigital.co.uk';
  }
};

export const isAccessTokenAvailable = () => !!localStorage.getItem('tokens');
export const getAccessToken = () => localStorage.getItem('tokens') || '';
export const clearAccessToken = () => localStorage.removeItem('tokens');

export const getHeaders = () => ({
  common: {
    Authorization: `Bearer ${JSON.parse(getAccessToken()).access_token}`,
  },
  language: {
    'Accept-Language': getLanguage(),
    'Content-Language': getLanguage(),
  },
});

export const setLocaleUsingCookies = (lang: string) => {
  if (env) {
    const domain = getDomain();
    const key = `i18nextLng_${env}`;
    cookieStorage.setItem(key, lang, {
      path: '/',
      domain,
    });
  }
};

export const setLocaleTempUsingCookies = (lang: string) => {
  if (env) {
    const domain = getDomain();
    const key = `i18nextLngTemp_${env}`;
    cookieStorage.setItem(key, lang, {
      path: '/',
      domain,
    });
  }
};

export const getLocaleUsingCookies = () => {
  const key = `i18nextLng_${env}`;
  return (cookieStorage.getItem(key) || null);
};

export const getLocaleTempUsingCookies = () => {
  const key = `i18nextLngTemp_${env}`;
  return (cookieStorage.getItem(key) || null);
};

export const isCropwiseOrSyngentaURL = (productURL: string) => {
  return productURL.includes('cropwise') || productURL.includes('syngentadigital');
};

export const tokenBelongsToAuthority = (authorizationToken: any, authority: any) => {
  try {
    const decoded: any = jwtDecode(authorizationToken);
    return (decoded.authorities || []).indexOf(authority) > -1;
  } catch (err) {
    return false;
  }
};

export const isUserPrivileged = () => {
  try {
    const token = JSON.parse(getAccessToken());
    return (
      tokenBelongsToAuthority(token.access_token, 'SUPER_USER') ||
      tokenBelongsToAuthority(token.access_token, 'SUPPORT')
    );
  } catch (err) {
    return false;
  }
};

export const removeClassNameFromID = (id: string, className: string) => {
  const element: any = document.getElementById(id);
  element?.classList?.remove(className);
}
export const getSearchParam = (locationSearch: any, param: any) => {
  return new URLSearchParams(locationSearch).get(param) || '';
};

export const getLanguage = () => getLocaleTempUsingCookies() || getLocaleUsingCookies() || localStorage.getItem('i18nextLng') || localStorage.getItem('currentLanguage') || 'en';

