import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';
import { translationFiles } from './translation.assets';
import { getLocaleTempUsingCookies, getLocaleUsingCookies } from './core/common-methods';
// tslint:disable-next-line: no-multi-spaces
const Lang = getLocaleTempUsingCookies() || getLocaleUsingCookies() || localStorage.getItem('i18nextLng') || localStorage.getItem('currentLanguage') || 'en';
const customDetector = {
  name: 'STLangDetector',
  lookup(): any {
    return Lang;
  }
};
const langDetector = new LanguageDetector();
langDetector.addDetector(customDetector);
const langResources = translationFiles;
i18n.on('languageChanged', lng => {
  moment.locale(lng);
});
i18n.use(initReactI18next).use(langDetector).init({
  resources: langResources,
  debug: false,
  defaultNS: 'translation',
  react: {
    useSuspense: false
  },
  //keySeparator: false, // we use content as keys
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  },
  detection: {
    order: ['STLangDetector']
  }
  // tslint:disable-next-line:no-empty
}).catch(() => {});
export default i18n;