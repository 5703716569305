import { IUserAccount, PasswordRecoveryDTO } from 'core/accounts/models';
import { axiosRequest } from 'core/axios';
import { getHeaders } from 'core/common-methods';
import { createRequestAction, RequestAction } from 'core/utils/actionUtils';
import { Dispatch } from 'redux';
import { UUID } from '../../core/utils/BasicModels';
import { ACTION_CURRENT_ACCOUNT } from './login.actions';

export const USER_PROFILE_EDIT: RequestAction = createRequestAction(
  'PUT_USER_PROFILE_EDIT',
);

export const USER_RECOVERY_MAIL_SENDER: RequestAction = createRequestAction(
  'POST_USER_RECOVERY_MAIL_SENDER',
);

export const DELETE_ACCOUNT_REQUEST: RequestAction = createRequestAction(
  'DELETE_ACCOUNT_REQUEST',
);

export const UPLOAD_PHOTO_REQUEST: RequestAction = createRequestAction(
  'UPLOAD_PHOTO_REQUEST',
);

export const DELETE_PHOTO_REQUEST: RequestAction = createRequestAction(
  'DELETE_PHOTO_REQUEST',
);

export const editUserProfile = (newData: any, user: IUserAccount) => async (dispatch: Dispatch) => {
  if (newData.first_name && newData.last_name && !newData.name) {
    newData.name = newData.first_name.concat(' ', newData.last_name);
  }
  try {
    dispatch({ type: USER_PROFILE_EDIT.REQUEST });
    const userEdited = await axiosRequest.put(
      `/v2/accounts/${user.id}`,
      newData, {
        headers: getHeaders().common,
      });

    dispatch({
      type: USER_PROFILE_EDIT.SUCCESS,
      payload: userEdited.data,
    });

    dispatch({
      type: ACTION_CURRENT_ACCOUNT.SUCCESS,
      payload: userEdited.data,
    });
    return userEdited;
  } catch (e) {
    dispatch({ type: USER_PROFILE_EDIT.ERROR, payload: e });
    throw e;
  }
};

export const accountRecoveryEmailSender =
  (valuesRequest: PasswordRecoveryDTO) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: USER_RECOVERY_MAIL_SENDER.REQUEST });

      const accountRecovery = await axiosRequest.post(
        `/v2/accounts/recovery`,
        valuesRequest,
        {
          headers: getHeaders().common,
        },
      );

      dispatch({
        type: USER_RECOVERY_MAIL_SENDER.SUCCESS,
        payload: accountRecovery.data,
      });

      dispatch({
        type: USER_RECOVERY_MAIL_SENDER.SUCCESS,
        payload: accountRecovery.data,
      });
      return accountRecovery.data;
    } catch (e) {
      dispatch({ type: USER_RECOVERY_MAIL_SENDER.ERROR, payload: e });
      throw e;
    }
  };

export const deleteAccount = (accountId: UUID) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: DELETE_ACCOUNT_REQUEST.REQUEST });
    const deletedAccount = await axiosRequest.delete(
      `/v2/accounts/${accountId}`,
      { headers: getHeaders().common });

    dispatch({
      type: DELETE_ACCOUNT_REQUEST.SUCCESS,
      payload: deletedAccount.data,
    });

    dispatch({
      type: DELETE_ACCOUNT_REQUEST.SUCCESS,
      payload: deletedAccount.data,
    });
    return deletedAccount;
  } catch (e) {
    dispatch({ type: DELETE_ACCOUNT_REQUEST.ERROR, payload: e });
    throw e;
  }
};

export const uploadAccountPhoto = (accountId: UUID, photo?: string | Blob) => async (dispatch: Dispatch) => {
  try {
    if (photo) {
      dispatch({ type: UPLOAD_PHOTO_REQUEST.REQUEST });

      const formData = new FormData();
      formData.append('photo', photo);
      const uploadPhotoResponse = await axiosRequest.post(
        `/v2/accounts/${accountId}/photo`,
        formData,
        { headers: { ...getHeaders().common, 'Content-Type': 'multipart/form-data' } });

      dispatch({
        type: UPLOAD_PHOTO_REQUEST.SUCCESS,
        payload: uploadPhotoResponse.data,
      });

      return uploadPhotoResponse.data;
    }

  } catch (e) {
    dispatch({ type: UPLOAD_PHOTO_REQUEST.ERROR, payload: e });
    throw e;
  }
};

export const deleteAccountPhoto = (accountId: UUID) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: DELETE_PHOTO_REQUEST.REQUEST });
    await axiosRequest.delete(
      `/v2/accounts/${accountId}/photo`,
      { headers: getHeaders().common });

    dispatch({
      type: DELETE_PHOTO_REQUEST.SUCCESS,
    });
  } catch (e) {
    dispatch({ type: DELETE_PHOTO_REQUEST.ERROR, payload: e });
    throw e;
  }
};
