export enum IdentityProvider {
    LOCAL = 'LOCAL',
    google = 'google',
    microsoft = 'microsoft',
    syngenta = 'syngenta',
}

export enum AvailableDocumentTypes {
    PRIVACY = 'privacy_notice',
    TERMS = 'terms_and_conditions',
}

export enum SelectedPages {
    PLANS = 'plans',
    MEMBERS_AND_CONTACTS = 'members_and_contacts'
}