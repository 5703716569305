import { AppState } from "app-redux/reducers";
import { RolloutState } from "app-redux/reducers/rollout.reducer";
import { createSelector } from "reselect";

export const getRolloutState = (state: AppState) => state.rollout || {} as RolloutState;

export const getRolloutLoading = createSelector(getRolloutState,
    (rolloutState) => rolloutState.loading);

export const getIsHierarchyManagementEnabled = createSelector(getRolloutState,
    (rolloutState) => rolloutState.flags?.['AccountsManagement.isHierarchyManagementEnabled']);

export const getIsInviteMemberMFeEnabled = createSelector(getRolloutState,
    (rolloutState) => rolloutState.flags?.['isInviteMemberMFeEnabled']);

export const getAccountsIdsWithInviteMFeEnabled = createSelector(getRolloutState,
    (rolloutState) => rolloutState.flags?.['accountsIdsWithInviteMFeEnabled'])
