import { matchPath } from 'react-router';
import { createSelector } from 'reselect';
import { history } from '../../core/history';
import { AppState } from '../reducers';
import { WorkspacesState } from '../reducers/workspacesReducer';
import qs from 'querystring';
import get from 'lodash/get';
import { store } from 'app-redux/store';

export const getWorkspacesState = (state: AppState) =>
  state.workspaces || ({} as WorkspacesState);

export const getWorkspaces = createSelector(
  getWorkspacesState,
  (licensingAccountsState) => licensingAccountsState.workspaces,
);

const getCurrentWorkspaceIdFromState = (licensingAccountsState: WorkspacesState) => licensingAccountsState.currentWorkspaceId;

export const getCurrentWorkspaceIdFromPath = (path: string) => {
  const pathParams = matchPath(path, {
        path: [
            '/app/workspaces/:accountId/*',
        ],
        exact: true,
        strict: true,
    })?.params as { accountId?: string };

  return (pathParams && pathParams.accountId) ? pathParams.accountId : undefined;
};

export const getCurrentWorkspace = createSelector(getWorkspacesState,
  (licensingAccountsState: WorkspacesState) => {
    const licensingAccountsList = licensingAccountsState.workspaces || [];
    const currentAccountId = getCurrentWorkspaceIdFromState(licensingAccountsState) || getCurrentWorkspaceIdFromPath(history.location.pathname);
    const currentAccountsList = licensingAccountsList.filter((account) => account.id === currentAccountId);
    if (currentAccountsList.length) {
      return currentAccountsList[0];
    }
    return null;
  },
);

export const getCurrentWorkspaceFromURL = () => {
  const locationSearch = window.location.search.replace('?', '');
  const query = qs.parse(locationSearch);
  const qsWorkspace: any = query.workspace;
  if (qsWorkspace) {
    return qsWorkspace;
  }
  return null;
};

export const getSelectedWorkspaceObject = createSelector(getWorkspacesState,
  (licensingAccountsState: WorkspacesState) => {
    const licensingAccountsList = licensingAccountsState.workspaces || [];
    const currentAccountId = getDefaultWorkspace();
    const currentAccountsList = licensingAccountsList.find((account) => account.id === currentAccountId);
    return currentAccountsList ? currentAccountsList: null;
  },
);

export const getDefaultWorkspace = (reduxState: any = store.getState()) => {
  const workspaceFromURL = getCurrentWorkspaceFromURL();
  if (workspaceFromURL) {
    return workspaceFromURL;
  }
  const currentWorkspaceId = get(reduxState, "workspaces.currentWorkspaceId", null);
  if (currentWorkspaceId) {
    return currentWorkspaceId;
  }
  return "";
};

export const getOrganizationFromURL = () => {
  const locationSearch = window.location.search.replace('?', '');
  const query = qs.parse(locationSearch);
  return query.organization ? (query.organization as string) : null;
};

export const getCurrentOrDefaultOrganization = (reduxState: AppState = store.getState()) => {
  const organizationFromURL = getOrganizationFromURL();
  if (organizationFromURL) return organizationFromURL;
  const currentOrganizationId: string | null= get(reduxState, "orgsState.currentOrgId", null);
  if (currentOrganizationId) return currentOrganizationId;
  return "";
};
