export const translationFiles = (ctx => {
  const keys = ctx.keys();
  const values = keys.map(ctx);
  const lang = keys.map(key => key.replace('./', ''));

  // @ts-ignore
  return lang.reduce((o, k, i) => {
    // @ts-ignore
    // @ts-ignore
    o[k.replace(/\.[^/.]+$/, '')] = {
      translation: values[i]
    };
    return o;
  }, {});
})(require.context('./assets/i18n', true, /.json/));